import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Eric Sutphen / Software Developer" />
    <h1>About</h1>
    <strong>I'm a software developer for General Dynamics.</strong>
    <p>
      I've been using Java and Oracle DB most recently, but I love learning about new tech.  Like this website that was built using Gatsby!
    </p>
  </Layout>
)

export default AboutPage
